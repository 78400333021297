import type { RecursivePartial } from '#types/utils'
import type { HelpConfig } from '#types/config/pages/help'

export default {
  brandClasses: {
    breadcrumbs: '<lg:(absolute right-0 top-0 mx-4 -mt-8) lg:mt-9',
    contactFormTitle: 'mb-3 mt-8 subtitle-3',
    layout: 'mb-6 flex pb-3 <lg:flex-col-reverse lg:(gap-7 mt-15)',
    sectionHeading: 'subtitle-2',
    sidebar: 'w-80 shrink-0 <lg:(mt-8 w-full)',
    tiles: 'grid gap-4 lg:cols-2'
  },
  sidebar: {
    brandClasses: {
      accordion: 'b-b b-t b-grey-70 py-4',
      accordionSpan: 'w-full flex items-center between hover:underlined',
      listItem: 'mb-0',
      sidebarLink: 'flex b-b b-t b-grey-70 py-4'
    },
    chevronSize: 'sm'
  }
} satisfies RecursivePartial<HelpConfig>
