import type { CartConfig } from '#types/config/pages/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    editItemCta: 'mt-4 flex items-center gap-2 underlined <lg:hidden body-3',
    floatingCta: () => 'ml-3',
    header: 'mb-4 lg:mb-6',
    headerTitle: 'subtitle-2',
    headerTotalItems: 'body-3 flex items-center justify-end',
    items: 'pb-8 md:pb-10',
    itemsPerShipping: 'md:mb-4',
    itemVariants: 'body-3',
    noItems: 'mx-a py-20 -mt-4 md:mt-0 text-center subtitle-3',
    price: 'c-grey-70'
  },
  brandStyles: {
    floatingCta: 'padding: 0.645rem',
  },
  cartItem: {
    thumbnails: {
      alignFill: 'center',
      unSharpMask: 100
    }
  },
  disableBonusPdpLinks: true,
  item: {
    links: 'body-3',
    actionMenu: {
      brandClasses: {
        button: 'py-2 px-4 gap-2 items-center',
        list: 'divide-grey-70'
      },
      dialogOptions: { type: 'panel' },
      iconSize: 'lg'
    },
  },
  outOfStock: {
    dialogOptions: { type: 'panel' },
    showRemoveAll: false,
    showRemoveItem: false,
    showSaveForLater: false
  },
  quickshop: {
    dialogOptions: { type: 'panel' },
  },
  savedForLater: {
    dialogOptions: { type: 'panel' },
    moveToCartIcon: 'cart'
  },
  thumbnails: {
    size: { width: 94, height: 118 },
    transformations: {
      alignFill: 'center',
      unSharpMask: 100
    }
  },
} satisfies RecursivePartial<CartConfig>
