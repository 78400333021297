import type { CartOrderSummaryConfig } from '#types/config/components/cart/orderSummary'
import type { RecursivePartial } from '#types/utils'

export default {
  loyalty: {
    loyaltyUsesVouchers: true
  },
  orderSummary: {
    header: 'subtitle-4',
    subtotal: 'body-3',
    total: 'subtitle-4'
  },
  showCheckoutCta: false,
  stickyCtas: {
    enabled: true,
    showBottomStickyCheckoutCta: false
  },
} satisfies RecursivePartial<CartOrderSummaryConfig>
